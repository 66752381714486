import React, {useEffect} from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import '../styles/globals.css'
import 'animate.css'
import {wrapper} from "../store";
import {Provider} from "react-redux";
import {DefaultSeo} from "next-seo";
import TagManager from "react-gtm-module"

Bugsnag.start({
	apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
	releaseStage: process.env.NEXT_PUBLIC_RELEASE_STAGE,
	enabledReleaseStages: [ 'production', 'staging' ],
	plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const MyApp = ({Component, ...rest}) => {
	const {store, props} = wrapper.useWrappedStore(rest);
	
	useEffect(() => {
		if (process.env.NEXT_PUBLIC_GTM_ID) {
			TagManager.initialize({
				gtmId: process.env.NEXT_PUBLIC_GTM_ID,
				auth: process.env.NEXT_PUBLIC_GTM_AUTH,
				preview: process.env.NEXT_PUBLIC_GTM_ENV
			})
		}
	}, []);
	
	return (
		<ErrorBoundary>
			<Provider store={store}>
				<>
					<DefaultSeo
						title={`PageName Updates`}
						description={`View all our site updates and release notes here`}
						openGraph={{
							type: 'website',
							url: process.env.NEXT_PUBLIC_BLOG_URL,
							title: `PageName Updates`,
							description: `View all our site updates and release notes here`,
							images: [
								{
									url: process.env.NEXT_PUBLIC_UPDATES_URL + '/images/og-image.png',
									width: 1200,
									height: 641,
									alt: `View all our site updates and release notes here`,
									type: 'image/png',
								}
							],
							siteName: 'PageName',
						}}
						twitter={{
							site: '@pagenamer',
							cardType: 'summary_large_image',
						}}
					/>
					<Component {...props.pageProps} />
				</>
			</Provider>
		</ErrorBoundary>
	);
}

export default MyApp;
