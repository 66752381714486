import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'
import axios from "axios";


const baseQuery = fetchBaseQuery({
	baseUrl: process.env.NEXT_PUBLIC_API_URL,
	prepareHeaders: (headers, { extra }) => {
		headers.set('Accept', `application/json`)
		// if (getCookie('XSRF-TOKEN')) {
		// 	headers.set('X-XSRF-TOKEN', getCookie('XSRF-TOKEN'))
		// }
		if (extra.cookies !== undefined) {
			headers.set('X-XSRF-TOKEN',  extra.cookies['XSRF-TOKEN'])
			
			// Build a cookie string from object
			const cookieValue = Object.entries(extra.cookies)
				// .filter(([k]) => k === 'JSESSIONID') // only include relevant cookies
				.map(([k, v]) => `${k}=${v}`) // rfc6265
				.join('; ');
			
			// return { 'Cookie': cookieValue }
			headers.set('Cookie', `${cookieValue}`)
			headers.set('referer', process.env.NEXT_PUBLIC_SSR_REFERRER)
			headers.set('origin', process.env.NEXT_PUBLIC_SSR_REFERRER)
		}
		
		return headers
	},
	credentials: "include",
})

const axiosBaseQuery = ({ baseUrl, headers } = { baseUrl: '', headers: {}}) =>
	async ({ url, method, body, params }) => {
		try {
			const result = await axios({url: baseUrl + url, method, data: body, params})
			return { data: result.data }
		} catch (axiosError) {
			let err = axiosError
			
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			}
		}
	}

export const apiSlice = createApi({
	reducerPath: 'api',
	baseQuery: axiosBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API_URL,
	}),
	extractRehydrationInfo(action, { reducerPath }) {
		// console.log(JSON.stringify(action, null, 4));
		if (action.type === HYDRATE) {
			return action.payload[reducerPath]
		}
	},
	endpoints: () => ({}),
})
